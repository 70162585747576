import selectAccount from '../components/select-account.vue'
import { getDictLists } from '@/filters/fromDict'
import AmountInput from '@/components/input/amount-input/amount-input.vue'
export const accountForm = (content) => {
  const span = 8
  return [
    {
      label: '付款申请单编码',
      prop: 'easNo',
      span,
      attrs: {
        placeholder: '请输入',
        disabled: true
      }
    },
    {
      label: '申报金额(元)',
      prop: 'declareAmount',
      span,
      attrs: {
        placeholder: '请输入',
        disabled: true
      }
    },
    {
      label: '审定金额(元)',
      prop: 'examineAmount',
      span,
      attrs: {
        placeholder: '请输入',
        disabled: true
      }
    },
    {
      label: '放款金额',
      prop: 'evidenceSum',
      span,
      attrs: {
        placeholder: '请输入',
        disabled: true
      }
    },
    {
      label: '借据编号',
      prop: 'evidenceCode',
      span,
      attrs: {
        placeholder: '请输入',
        disabled: true
      }
    },
    {
      label: '供应商二级清分账户户名',
      prop: 'accountName',
      span,
      attrs: {
        placeholder: '请输入',
        disabled: true,
        rowData: content.accountData,
        // accountTypes: '0',
        gysId: content.gysId
      },
      tag: selectAccount,
      on: {
        selectAccount (data) {
          content.selectAccount(data)
        }
      },
      rules: [
        { required: true, message: '请选择供应商二级清分账户户名', trigger: ['blur', 'change'] }
      ]
    },
    {
      label: '供应商二级清分账户开户行',
      prop: 'clearingBankName',
      span,
      attrs: {
        placeholder: '请输入',
        disabled: true
      },
      rules: [
        { required: true, message: '请输入供应商二级清分账户开户行', trigger: ['blur', 'change'] }
      ]
    },
    {
      label: '供应商二级清分账户账号',
      prop: 'accountNo',
      span,
      attrs: {
        placeholder: '请输入',
        disabled: true
      },
      rules: [
        { required: true, message: '请输入供应商二级清分账户账号', trigger: ['blur', 'change'] }
      ]
    },
    {
      label: '清分总金额(元)',
      prop: 'sortingAmount',
      span,
      rules: [
        { required: true, message: '请输入清分总金额', trigger: ['blur', 'change'] }
      ],
      attrs: {
        placeholder: '请输入',
        type: 'Y'
      },
      tag: AmountInput
    }
  ]
}
// 账户表格
export const accountTable = (content) => {
  return [
    {
      label: '付款申请单编码',
      prop: 'paymentOrder',
      minWidth: '150px'
    },
    {
      label: '申请金额',
      prop: 'paymentOrder',
      minWidth: '100px',
      align: 'right'
    },
    {
      label: '审定金额',
      prop: 'paymentOrder',
      minWidth: '100px',
      align: 'right'
    }
  ]
}
// 选择账户信息
export const selectAccountColumns = () => {
  return [
    {
      label: '',
      prop: 'radio',
      width: '80px'
    },
    {
      label: '户名',
      prop: 'account',
      align: 'left'
    },
    {
      label: '开户行',
      prop: 'bankName',
      align: 'left'
    },
    {
      label: '账号',
      prop: 'accountNo',
      align: 'left'
    },
    {
      label: '账户类型',
      prop: 'accountType',
      formatter: row => {
        const [obj] = getDictLists('SUPPLIER_BANK_TYPE').filter(item => item.dictId === row.accountType.toString())
        return obj ? obj.dictName : '-'
      }
    }
  ]
}
// 新增账户信息
export const addAccountColumns = () => {
  return [
    {
      label: '户名',
      prop: 'account'
    },
    {
      label: '开户行',
      prop: 'bankName'
    },
    {
      label: '账号',
      prop: 'accountNo'
    },
    {
      label: '账户类型',
      prop: 'accountType'
    },
    {
      label: '操作',
      prop: 'action'
    }
  ]
}
